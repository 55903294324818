import { db } from '../../firebase/firebaseapi'
import { collection, doc, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { COLLECTIONS, CATALOG } from '../../constants/constants'

export const addNotifications = async (data, companyId) => {
  try {
    const notificationRef = collection(db, COLLECTIONS.COMPANIES, companyId.toString(), CATALOG.NOTIFICATION)
    let imageUrl = data?.image;

    const formData = {
      name: {
        en: data.name?.en || '',
        ar: data.name?.ar || '',
      },
      description: {
        en: data.description?.en || '',
        ar: data.description?.ar || '',
      },
      image: imageUrl, // Store the image URL instead of the File object
      status: data.status,
      notificationType: data?.notificationType || '',
      link: data?.link || '',
      createdAt: serverTimestamp(),
    }

    await addDoc(notificationRef, formData)
  } catch (error) {
    console.error('Error adding notification: ', error)
  }
}

export const updateNotificationData = async (id, data, companyId) => {
  try {
    const notificationRef = doc(db, COLLECTIONS.COMPANIES, companyId.toString(), CATALOG.NOTIFICATION, id)
    let imageUrl = data?.image;

    const updatedData = {
      name: {
        en: data.name?.en || '',
        ar: data.name?.ar || '',
      },
      description: {
        en: data.description?.en || '',
        ar: data.description?.ar || '',
      },
      image: imageUrl,
      status: data.status,
      notificationType: data?.notificationType || '',
      link: data?.link || '',
      updatedAt: serverTimestamp(),
    }

    await updateDoc(notificationRef, updatedData)
  } catch (error) {
    console.error('Error updating notification: ', error)
  }
}
