import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import CropImage from '@common/src/common/ImageUpload/CropImage'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'

import { STATUS_OPTIONS, DEFAULT_NOTIFICATION_TYPES, IMG_UPLOAD } from '@hub/constants/constants'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import { addNotifications, updateNotificationData } from '../../../service/firebase/notification'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'


const NotificationForm = ({ id, data, isEdit, onComplete, showAlert, onNotificationTypeChange }) => {

  const validationSchema = Yup.object({

    status: Yup.string().required('Status is required'),
    image: Yup.mixed()
      .required('Please upload an image')
      .test('fileFormat', 'Please upload an image in .jpg, .jpeg, or .png format', (value) => {
        if (typeof value === 'string') return true
        const validFormats = ['image/jpeg', 'image/png', 'image/jpg']
        return value && validFormats.includes(value.type)
      })
      .test('fileSize', 'Max file size is restricted to 4 MB', (value) => {
        if (typeof value === 'string') return true
        const maxSize = 4 * 1024 * 1024 // 4 MB
        return value && value.size <= maxSize
      }),
    name: Yup.object()
      .shape({
        en: Yup.string()
          .required('English title is required')
          .max(50, 'English title cannot exceed 50 characters'), // Max length for English title
        ar: Yup.string()
          .required('Arabic title is required')
          .max(50, 'Arabic title cannot exceed 50 characters'), // Max length for Arabic title
      })
      .required('Name is required'),
    description: Yup.object()
      .shape({
        en: Yup.string()
          .required('English description is required')
          .max(200, 'English description cannot exceed 200 characters'), // Max length for English description
        ar: Yup.string()
          .required('Arabic description is required')
          .max(200, 'Arabic description cannot exceed 200 characters'), // Max length for Arabic description
      })
      .required('Description is required'),
  })

  const toast = useToast()
  const cropImageRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    image: '',
    status: '',
  })
  const [notificationTypeOptions, setNotificationTypeOptions] = useState([]);
  const [notificationTypeValueLabel, setNotificationTypeValueLabel] = useState('Choose the');
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchLabel = async (value) => {
    const [type] = value.split('/');
    const options = await handleChange(type);
    return options.find((item) => item.value === value)?.label || '';
  };

  const getSingleCategoryData = async () => {
    setInitialValues({
      name: data?.name || {},
      description: data?.description || {},
      status: data?.status || '',
      image: data?.image || '',
      notificationType: data?.link ? { value: data?.link.split('/')[0], label: data.link.split('/')[0] } : '',
      link: data?.link
        ? { value: data.link, label: await fetchLabel(data.link) }
        : '',
    })
  }

  useEffect(() => {
    getSingleCategoryData()
  }, [])


  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }


  const prepareFormData = (values) => ({
    name: {
      en: values?.name.en,
      ar: values?.name.ar,
    },
    description: {
      en: values?.description.en,
      ar: values?.description.ar,
    },
    image: values?.image,
    status: values?.status,
    notificationType: values?.notificationType.value,
    link: values?.link.value,
  })

  const onUploadSuccess = async ({ values, actions, url="" }) => {
    setIsLoading(true)
    actions?.setSubmitting?.(true);
    try {
      let formData = {
        ...values,
        image: url,
        companyId: selectedCompany?.value,
        ...(data?.id && { id: data?.id }),
      };
      if (isEdit) {
        await updateNotification(formData, actions);
      } else {
        let addData = prepareFormData({...values, image: url});
        await addNotifications(addData, selectedCompany?.value);
        actions?.resetForm?.();
        HSOverlay.close(`#${id}`);
        onComplete?.();
        showAlert(true);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      actions?.setSubmitting?.(false);
      setIsLoading(false)
    }
  };

  const handleSubmit = async (values, actions) => {
    actions?.setSubmitting?.(true);
    if (typeof values.image === 'string') {
      // If the image is unchanged (already a string/URL), proceed to save directly
      await onUploadSuccess({ values, actions, url: values.image });
    } else {
      // If a new image is uploaded, trigger the upload
      cropImageRef.current.uploadImage();
    }
  };
  

  const updateNotification = async (values, actions) => {
    try {
      actions?.setSubmitting?.(true);
      const formData = prepareFormData(values);
      await updateNotificationData(data.id, formData, selectedCompany?.value);
      toast.success(isEdit ? 'Notification updated successfully!' : 'Notification created successfully!');
      actions?.resetForm?.();
      HSOverlay.close(`#${id}`);
      onComplete?.();
    } catch (error) {
      toast.error(isEdit ? 'Error updating notification.' : 'Error creating notification.');
      console.error('Form Submission Error:', error);
    } finally {
      actions?.setSubmitting?.(false);
    }
  };


  const handleChange = async (e) => {
    try {
      let optionArray = [];
      setNotificationTypeOptions([]); // Clear previous options
      if (e === 'CATEGORY' && categoryTypeOptions.length > 0) {
        optionArray = categoryTypeOptions;
      }
      else if (e === 'PRODUCT' && productTypeOptions.length > 0) {
        optionArray = productTypeOptions;
      }
      else {
        const dataArray = await onNotificationTypeChange(e); // Fetch new options
        if (dataArray) {
          if (e === 'CATEGORY') {
            setNotificationTypeValueLabel('Choose the - Category');
            setCategoryTypeOptions(dataArray)
          }
          else if (e === 'PRODUCT') {
            setNotificationTypeValueLabel('Choose the - Product');
            setProductTypeOptions(dataArray)
          }
          optionArray = dataArray
        }
      }
      setNotificationTypeOptions(optionArray); // Update state with new options
      return optionArray;
    } catch (error) {
      console.error('Error in handling banner type change:', error);
    }
  };



  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Save'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
      loading={isLoading}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (
          <div className="grid grid-cols-12 gap-6">
            {/* Category Name and Starting Price */}
            <div className="xl:col-span-12 col-span-12">
              <div className="box">
                <div className="box-body add-products !p-0">
                  <div className="grid grid-cols-3 gap-6">
                    <MultiLanguageInput
                      type="input"
                      label="Title"
                      id="title"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                      required={true}
                    />
                    <MultiLanguageInput
                      type="textarea"
                      label="Description"
                      id="category-description-text"
                      name="description"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                      required={true}
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      onChange={(e) => setFieldValue('status', e.value)}
                      errorText={errors.status && touched.status ? errors.status : null}
                      required={true}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    <SelectBox
                      name="notificationType"
                      label="Choose Redirect Type"
                      value={values.notificationType} // Set value from form state (e.g., 'CATEGORY')
                      onChange={async (e) => {
                        setFieldValue('notificationType', e) // Update form field value
                        setFieldValue('notificationTypeValue', '') // Update form field value
                        await handleChange(e.value) // Fetch options based on selection
                      }}
                      id="notificationType"
                      options={DEFAULT_NOTIFICATION_TYPES}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    <SelectBox
                      name="link"
                      label={notificationTypeValueLabel}
                      value={values.link} // Set value from form state (e.g., 'CATEGORY')
                      onChange={(e) => {
                        setFieldValue('link', e)
                      }}
                      id="link"
                      options={notificationTypeOptions}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    <CropImage
                      value={values?.image}
                      onChange={(file) => {
                        setFieldValue('image', file)
                      }}
                      errorText={touched.image && errors.image ? errors.image : null}
                      ref={cropImageRef}
                      path={`company/${selectedCompany?.value}/notifications/not_`}
                      onUploadSuccess={onUploadSuccess}
                      onUploadError={onUploadError}
                      setFieldValue={setFieldValue}
                      minCrop={IMG_UPLOAD.PUSH_NOTIFICATION_IMAGE.MIN_RESOLUTION}
                    />
                  </div>
                  {isEdit && values?.image && typeof values?.image === 'string' && (
                    <div className="preview-img">
                      <PreviewImage
                        src={values?.image}
                        isRemovable={true}
                        onRemove={() => {
                          setFieldValue('image', '')
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}
NotificationForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    modifiers: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    status: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,

  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.string,
  showAlert: PropTypes.func.isRequired,
  onNotificationTypeChange: PropTypes.func.isRequired,
}

export default NotificationForm
