import { useCallback, useState, useImperativeHandle, forwardRef } from 'react'
import FileDropZone from './components/FileDropZone'
import ImageCropper from './components/ImageCroper'
import PropTypes from 'prop-types'
import { getStorage, ref as fbRef, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { useFormikContext } from 'formik'
import { reportLog, reportError } from '../helper'

const getFileExtension = (fileName) => fileName.split('.').pop()

const CropImage = forwardRef(
  ({ value, onChange, errorText, path, onUploadSuccess, onUploadError, minCrop, setCropFile, onUploadStart }, ref) => {
    const [remoteImage, setRemoteImage] = useState(value || '') // Set initial value from Formik
    const { values, actions } = useFormikContext()
    const [localImage, setLocalImage] = useState('')
    const [zoom, setZoom] = useState(1)
    const [croppedImage, setCroppedImage] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [localImgPath, setLocalImgPath] = useState(null)

    const isImageSelected = !!(remoteImage || localImage)

    const onDrop = useCallback(
      (acceptedFiles) => {
        setRemoteImage('')
        setLocalImage(URL.createObjectURL(acceptedFiles[0]))
        setLocalImgPath(acceptedFiles[0]?.path)
        onChange?.(acceptedFiles[0])
      },
      [onChange]
    )

    const handleOnZoom = useCallback((zoomValue) => {
      setZoom(zoomValue)
    }, [])

    // Firebase upload function
    const handleFirebaseUpload = async () => {
      if (!croppedImage) {
        console.log('No image to upload')
        return null
      }

      onUploadStart?.({ actions })
      const storage = getStorage()
      const randomString = Date.now()
      const storageRef = fbRef(storage, `${path}_${randomString}.${getFileExtension(localImgPath)}`)
      try {
        const uploadTask = uploadBytesResumable(storageRef, croppedImage)
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            reportLog(`Upload is ${progress}% done`, 'info')
          },
          (error) => {
            reportError('Upload failed:', error)
          },
          () => {
            reportLog('Upload completed successfully', 'info')
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL)
              onUploadSuccess?.({ url: downloadURL, values, actions })
            })
          }
        )
      } catch (error) {
        reportError('Upload error:', error)
        onUploadError(error)
      }
    }

    // Expose the upload function to the parent component via the `ref`
    useImperativeHandle(ref, () => ({
      uploadImage: handleFirebaseUpload,
    }))

    if (!isImageSelected)
      return (
        <div className="space-y-4 w-full p-4">
          <FileDropZone onDrop={onDrop} />
          {errorText && (
            <div className="text-red-600" style={{ color: 'red' }}>
              {errorText}
            </div>
          )}
        </div>
      )
    const onCropImage = (croppedImage) => {
      setCroppedImage(croppedImage)
      setCropFile?.(croppedImage)
    }
    return (
      <div className="flex-col">
        <div className="space-y-4 w-96 p-4">
          <FileDropZone onDrop={onDrop} />
        </div>
        <div className=" p-4 flex-1 flex items-center justify-center">
          <ImageCropper
            zoom={zoom}
            onZoomChange={handleOnZoom}
            rotation={rotation}
            onRotationChange={setRotation}
            source={remoteImage || localImage}
            onCrop={onCropImage}
            width={minCrop?.WIDTH || 1080}
            height={minCrop?.HEIGHT || 1920}
          />
        </div>
        {errorText && (
          <label className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
            {errorText}
          </label>
        )}
      </div>
    )
  }
)

CropImage.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  path: PropTypes.string.isRequired,
  onUploadError: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  minCrop: PropTypes.shape({
    WIDTH: PropTypes.number,
    HEIGHT: PropTypes.number,
  }),
  setCropFile: PropTypes.func,
  onUploadStart: PropTypes.func,
}

export default CropImage
